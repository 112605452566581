header {
  padding: 10px 0;
}

.navbar-brand {
  padding: 0 20px;
  line-height: 50px;
}

.navbar-brand-logo {
  max-width: 9rem;
}

.navbar-brand-logo-chip {
  margin-left: 10px;
  font-weight: bolder;
  text-transform: capitalize;
}

.chip {
  display: inline-block;
  padding: 0px 15px;
  border-left: 1px dashed rgb(var(--bs-primary-rgb));
  /* border-radius: 5px; */
  color: rgb(var(--bs-border-color-rgb));
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight: bold;
}

input[type="checkbox"] {
  width: 1.5em;
  height: 1.5em;
  /* margin-right: 15px; */

  margin-top: 0;
}

.form-check label {
  margin-left: 15px;
}

.user-card,
.contract-card {
  margin-top: 25px;
}

.footnote {
  font-size: 0.8rem;
  color: rgb(100, 100, 100);
}

.form-select {
  padding: 0.5rem 1rem;
}

.form-error {
  color: rgb(195, 25, 25);
  font-size: 0.85rem;
  padding-left: 10px;
}

.multi-level-list {
  list-style: none; /* Remove default bullets */
  padding: 0;
  margin: 0 0px;
  color: black;
}

.multi-level-list > li {
  position: relative;
  margin-left: 0px; /* No indentation for main sections */
  /* margin-bottom: 20px;; */
  /* color: rgb(18, 130, 200); */
}

.multi-level-list > li > ol {
  list-style: none; /* Remove default list style */
  /* margin-top: 20px; */
  padding-left: 30px;
  /* margin-top: 5px; */
  counter-reset: item; /* Initialize counter for first level of subsections */
}

.multi-level-list > li > ol > li:before {
  content: counter(item) "."; /* Integer counter for first level of subsections */
  counter-increment: item; /* Increment counter */
  display: inline-block; /* Changed from block */
  position: absolute; /* Changed from absolute */
  left: 0; /* Adjusted */
  margin-right: 10px; /* Added padding */
}

.multi-level-list > li > ol > li > ol {
  list-style: none; /* Remove default list style */
  padding-left: 20px;
  /* margin-top: 5px; */
  counter-reset: sub-item; /* Initialize counter for second level of subsections */
}

.multi-level-list > li > ol > li > ol > li:before {
  content: "(" counter(sub-item, lower-alpha) ")"; /* Lower alpha with parentheses */
  counter-increment: sub-item; /* Increment counter */
  display: inline-block; /* Changed from block */
  position: absolute; /* Changed from absolute */
  left: 0; /* Adjusted */
  margin-right: 10px; /* Added padding */
  margin-left: 10px;
}

.multi-level-list > li > ol > li > ol > li > ol {
  list-style: none; /* Remove default list style */
  padding-left: 40px;
  /* margin-top: 5px; */
  counter-reset: sub-sub-item; /* Initialize counter for third level of subsections */
}

.multi-level-list > li > ol > li > ol > li > ol > li:before {
  content: "(" counter(sub-sub-item, lower-roman) ")"; /* Lower roman with parentheses */
  counter-increment: sub-sub-item; /* Increment counter */
  display: inline-block; /* Changed from block */
  position: absolute; /* Changed from absolute */
  left: 0; /* Adjusted */
  margin-right: 10px; /* Added padding */
}

.section-title {
  font-weight: bold;
  text-decoration: underline;
  margin-right: 10px;
}

.special-title {
  display: block; /* Make the title display on its own line */
  text-transform: uppercase; /* Capitalize all letters */
  font-weight: bold; /* Make the title bold */
  margin-top: 20px; /* Add space above the title */
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.section-content {
  display: block;
  margin-bottom: 10px;
}

/* Specific level styling */
.multi-level-list > li > span.section-content {
  /* First level */
}

.multi-level-list > li > ol > li > span.section-content {
  /* Second level */
  /* color: #2fd20a; */
  margin-left: 30px;
}

.multi-level-list > li > ol > li > ol > li > span.section-content {
  /* Third level */
  /* color: #ee0808; */
  margin-left: 45px;
}

/* Make all counters bold */
.multi-level-list > li > ol > li:before,
.multi-level-list > li > ol > li > ol > li:before,
.multi-level-list > li > ol > li > ol > li > ol > li:before {
  font-weight: bold;
}

.exhibit-title {
  display: block;
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
  text-align: center;
}

.exhibit-subtitle {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
