/* src/HomePage.css */
.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: #f0f2f5;
  height: 100vh;
}

.home-header {
  background-color: #282c34;
  padding: 20px;
  border-radius: 10px;
  color: white;
  margin-bottom: 20px;
}

.main {
  max-width: 800px;
  margin: 0 auto;
}

section {
  margin-bottom: 20px;
}

h2 {
  color: #282c34;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 10px 0;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
